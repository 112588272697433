import React, { useState } from "react";
import Om from "./components/Om";
import Tabell from "./components/Tabell";
import Spiller from "./components/Spiller";
import Turneringer from "./components/turneringer/Turneringer";
import AchievementPage from "./components/achievements/AchievementPage";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import players from "./files/players.json";
import tournaments from "./files/tournament_objects.json";

import "./App.scss";
import Container from "@material-ui/core/Container";

function App() {
  const [spillere, setSpillere] = useState([]);

  const turneringer = tournaments.sort((a, b) => {
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  });

  const getStreak = (playerTournaments, allTournaments) => {
    if (!playerTournaments || !allTournaments) return 0;

    if (playerTournaments.length === allTournaments.length)
      return allTournaments.length;

    let streak = 0;

    for (let i = 0; i < allTournaments.length; i++) {
      if (!playerTournaments[i]) return streak;
      if (
        allTournaments[allTournaments.length - i - 1].date !==
        playerTournaments[i].date
      )
        return streak;
      streak = streak + 1;
    }

    return streak;
  };

  const playersWithScore = players.map((player) => {
    const bulletPoints = player.tournaments
      ?.filter((t) => t.key === "bullet")
      .sum("score");
    const blitzPoints =
      2 * player.tournaments?.filter((t) => t.key === "blitz").sum("score");
    const chess960Points =
      2 * player.tournaments?.filter((t) => t.key === "chess960").sum("score");
    const participationPoints = 10 * player.tournaments?.length;

    const firstPlaces = player.tournaments?.filter((t) => t.rank === 1).length;
    const secondPlaces = player.tournaments?.filter((t) => t.rank === 2).length;
    const thirdPlaces = player.tournaments?.filter((t) => t.rank === 3).length;
    const fourthPlaces = player.tournaments?.filter((t) => t.rank === 4).length;

    const rankPoints =
      firstPlaces * 20 +
      secondPlaces * 14 +
      thirdPlaces * 10 +
      fourthPlaces * 6;

    const fullScore =
      bulletPoints +
      blitzPoints +
      chess960Points +
      participationPoints +
      rankPoints;

    let prevFullScore = fullScore;

    const streak = getStreak(player.tournaments, turneringer);

    const maxRating = player.tournaments
      ? Math.max(...player.tournaments.map((o) => o.rating), 0)
      : 0;

    const ratingGroup =
      maxRating < 1700 ? "U1700" : maxRating < 2200 ? "U2200" : "";

    return {
      ...player,
      streak: streak,
      fullScore: fullScore,
      bulletPoints: bulletPoints,
      blitzPoints: blitzPoints,
      chess960Points: chess960Points,
      participationPoints: participationPoints,
      firstPlaces: firstPlaces,
      secondPlaces: secondPlaces,
      thirdPlaces: thirdPlaces,
      fourthPlaces: fourthPlaces,
      maxRating: maxRating,
      ratingGroup: ratingGroup,
      prevFullScore: prevFullScore,
    };
  });

  if (!spillere.length) {
    setSpillere(playersWithScore);
  }

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <a href="/">
          <img alt="Logo" src="league_logo.png" height="32px;" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <ul className="nav navbar-nav">
            <li>
              <Link className="nav-item nav-link" to="/">
                Standings
              </Link>
            </li>
            <li>
              <Link className="nav-item nav-link" to="/achievements">
                Achievements
              </Link>
            </li>
            <li>
              <Link className="nav-item nav-link" to="/tournaments">
                Tournaments
              </Link>
            </li>
            <li>
              <Link className="nav-item nav-link" to="/about">
                About
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="wrap-main">
        <Container className="main-container" maxWidth="lg">
          <Switch>
            <Route path={process.env.PUBLIC_URL + "/about"}>
              <Om />
            </Route>
            <Route exact path="/">
              <Tabell spillere={spillere} alle_turneringer={turneringer} />
            </Route>
            <Route path="/tournaments">
              <Turneringer tournaments={tournaments} />
            </Route>
            <Route path="/achievements">
              <AchievementPage />
            </Route>
            <Route
              path="/player/:brukernavn"
              component={(props) => (
                <Spiller
                  brukernavn={props.match.params.brukernavn}
                  spillere={spillere}
                  alle_turneringer={turneringer}
                />
              )}
            />
          </Switch>
        </Container>
      </div>
      <footer id="sticky-footer" className="py-4 bg-light footer">
        <a
          href="https://facebook.com/offerspillsk"
          target="_blank"
          rel="noopener noreferrer"
          className="fa fa-facebook"
          style={{ fontSize: "32px", color: "#3b5998" }}
          aria-hidden="true"
        >
          <></>
        </a>
        <a
          href="https://twitter.com/offerspill"
          target="_blank"
          rel="noopener noreferrer"
          className="fa fa-twitter"
          style={{ fontSize: "32px", color: "#1DA1F2" }}
          aria-hidden="true"
        >
          <></>
        </a>
        <a
          href="https://github.com/offerspill"
          target="_blank"
          rel="noopener noreferrer"
          className="fa fa-github"
          style={{ fontSize: "32px", color: "#000" }}
        >
          <></>
        </a>
      </footer>
    </Router>
  );
}

export default App;
