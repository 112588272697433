import React, { useState, useEffect, useRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
/* eslint-disable */

import "../App.scss";
import ReactTooltip from "react-tooltip";
import streak2 from "../icons/streak2.png";
import streak5 from "../icons/streak5.png";
import streak10 from "../icons/streak10.png";
import scoring from "../icons/scoring.png";
import sjakkbrett from "../icons/chessboard.png";
import mouse from "../icons/cursor.png";
import ach from "../achievements";
import Achievements from "./achievements/Achievements";

function Spiller({ brukernavn, spillere, alle_turneringer }) {
  const [lichessSpillerState, setLichessSpillerState] = useState({});
  const [xp, setXp] = useState(0);
  const player = spillere.find((player) => player.username === brukernavn);

  const xpIncreaseFx = useRef(null);
  const xpBarFill = useRef(null);

  const xpIncreaseFxFlicker = useRef(null);

  const maxPoints = ach
    .filter((el) => !el.secret && !el.fr_specific)
    .reduce((a, b) => a + (b.xp || 0), 0);

  const percentage = xp / maxPoints;

  console.log("percentage", percentage);

  const animateLevelBar = () => {
    if (!xpIncreaseFx.current || !xpBarFill.current) return;

    xpIncreaseFx.current.style.display = "inline-block";
    xpBarFill.current.style.boxShadow =
      /*"0px 0px 15px #06f,*/ "-5px 0px 10px #fff inset";
    setTimeout(function () {
      xpBarFill.current.style.transition = "all 2s ease";
      xpBarFill.current.style.width = `${percentage * 100}%`;
    }, 1000);
    //setTimeout(function(){document.getElementById("#xp-increase-fx").fadeOut(500);xpBarFill.current.style.WebkitTrasition = "all 0.5s ease";},2000);
    //setTimeout(function(){xpBarFill.current.style.width = "0.1%";},3000);
  };

  useEffect(() => {
    animateLevelBar();
  }, [xp]);

  useEffect(() => {
    if (!player) return;

    async function fetchData() {
      const res = await fetch(
        "https://lichess.org/api/user/" + player.username
      );
      res
        .json()
        .then((res) => setLichessSpillerState(res))
        .catch(() => {
          console.log("Kunne ikke hente spiller");
        });
    }

    fetchData();

    window.scrollTo(0, 0);
  }, []);

  if (!player) {
    return (
      <h2>
        "{brukernavn}" has not played in any League tournaments. If you think
        this is wrong, contact offerspill@gmail.com
      </h2>
    );
  }

  const cumScores = [];
  let sum = 0;

  let scores = [];

  for (let i = 0; i < alle_turneringer.length; i++) {
    const spiltTurnering = player.tournaments?.find(
      (obj) => obj.date === alle_turneringer[i].date
    );
    let currentScore = 0;
    let pointColor = "#E6ADA3";

    if (spiltTurnering) {
      currentScore = spiltTurnering.score;
      sum += spiltTurnering.score;
    } else {
      pointColor = "#FFF";
    }

    cumScores.push({ y: sum, color: pointColor });
    scores.push(currentScore);
  }

  const labels = alle_turneringer.map((t) => {
    let variant = "";

    if (t.key === "chess960") variant = "Fischersjakk";
    if (t.key === "blitz") variant = "Lynsjakk";
    if (t.key === "bullet") variant = "Bullet";

    return variant + " " + t.date;
  });

  const options = {
    chart: {
      spacingTop: 20,
      backgroundColor: "#272521",
    },
    title: {
      text: "Tournament points",
      style: {
        color: "#BABABA",
      },
    },
    subtitle: {
      text: "White points indicates tournaments not participated in.",
      style: {
        color: "#BABABA",
      },
    },
    tooltip: {
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}:</td>' +
        '<td style="padding:0"><b>{point.y}<br></b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    xAxis: {
      categories: labels,
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: "#BABABA",
      },
    },
    series: [
      {
        name: "Points",
        data: scores,
        type: "column",
        color: "#5D7BDE",
      },
      {
        name: "Cumulative",
        data: cumScores,
        color: "#E6ADA3",
      },
    ],
  };

  const getStreakIcon = (streak) => {
    if (streak > 9) return streak10;
    if (streak > 4) return streak5;
    if (streak > 1) return streak2;

    return "";
  };

  const streakIcon = getStreakIcon(player.streak);
  const fornavn =
    lichessSpillerState.profile && lichessSpillerState.profile.firstName
      ? lichessSpillerState.profile.firstName
      : "";
  const etternavn =
    lichessSpillerState.profile && lichessSpillerState.profile.lastName
      ? lichessSpillerState.profile.lastName
      : "";
  let navn = fornavn + " " + etternavn;
  if (player.username === "DrNykterstein") {
    navn = "Magnus Carlsen";
  }
  const tittel = lichessSpillerState.title ? lichessSpillerState.title : "";

  const streakClass =
    player.streak > 19 ? "streakIcon streak-20" : "streakIcon";

  return (
    <div>
      <ReactTooltip />
      <h2
        data-tip={`${lichessSpillerState.online ? "Online on Lichess" : ""}`}
        className={`player-username ${
          lichessSpillerState.online ? "online" : ""
        }`}
      >
        {tittel} {player.username}
      </h2>
      <div className="navn">{navn ? navn : null}</div>
      <div className="perfs">
        {player.streak > 1 ? (
          <div data-tip="Streak" className="streak perfchild">
            <h4>{player.streak}</h4>{" "}
            <img
              alt="Streak"
              src={streakIcon}
              className={streakClass}
              height="28px"
            />
          </div>
        ) : null}
        <div data-tip="XP" className="total perfchild">
          <h4>{xp}</h4> <img src={scoring} height="28px" />
        </div>
        <div data-tip="Number of games" className="partier perfchild">
          <h4>{player.totalt_partier ? player.totalt_partier : 0}</h4>
          <img alt="Chess board" src={sjakkbrett} height="28px" />
        </div>
        <div data-tip="Number of moves" className="deltager perfchild">
          <h4>{player.antall_trekk ? player.antall_trekk : 0}</h4>
          <img alt="Number of moves" src={mouse} height="28px" />
        </div>
      </div>
      <div className="collapse" id="detaljertePoeng">
        <div className="card card-body">
          <div className="poenginfo">
            {player.bulletPoints ? (
              <div>Bullet: {player.bulletPoints}</div>
            ) : null}
            {player.blitzPoints ? (
              <div>
                Lynsjakk: {player.blitzPoints / 2} * 2 = {player.blitzPoints}
              </div>
            ) : null}
            {player.chess960Points ? (
              <div>
                Fischersjakk: {player.chess960Points / 2} * 2 ={" "}
                {player.chess960Points}
              </div>
            ) : null}
            <div>
              Deltagerpoeng: {player.tournaments?.length}{" "}
              {player.tournaments?.length > 1 ? (
                <span>turneringer</span>
              ) : (
                <span>turnering</span>
              )}{" "}
              * 10 = {player.participationPoints}
            </div>
            {player.firstPlaces ? (
              <div>
                {player.firstPlaces}{" "}
                {player.firstPlaces > 1 ? (
                  <span>førsteplasser</span>
                ) : (
                  <span>førsteplass</span>
                )}{" "}
                gir {player.firstPlaces * 20} poeng
              </div>
            ) : null}
            {player.secondPlaces ? (
              <div>
                {player.secondPlaces}{" "}
                {player.secondPlaces > 1 ? (
                  <span>andreplasser</span>
                ) : (
                  <span>andreplass</span>
                )}{" "}
                gir {player.secondPlaces * 14} poeng
              </div>
            ) : null}
            {player.thirdPlaces ? (
              <div>
                {player.thirdPlaces}{" "}
                {player.thirdPlaces > 1 ? (
                  <span>tredjeplasser</span>
                ) : (
                  <span>tredjeplass</span>
                )}{" "}
                gir {player.thirdPlaces * 10} poeng
              </div>
            ) : null}
            {player.fourthPlaces ? (
              <div>
                {player.fourthPlaces}{" "}
                {player.fourthPlaces > 1 ? (
                  <span>fjerdeplasser</span>
                ) : (
                  <span>fjerdeplass</span>
                )}{" "}
                gir {player.fourthPlaces * 6} poeng
              </div>
            ) : null}
            <div>
              <b>Totalt: {player.fullScore}</b>
            </div>
          </div>
        </div>
      </div>
      <div id="account-bar-wrapper">
        <div id="account-bar">
          <div id="account-bar-level">PROGRESS</div>
          <div id="xp-bar">
            <div id="xp-bar-fill" ref={xpBarFill}>
              <div id="xp-increase-fx" ref={xpIncreaseFx}>
                <div id="xp-increase-fx-flicker" useRef={xpIncreaseFxFlicker}>
                  <div className="xp-increase-glow1"></div>
                  <div className="xp-increase-glow2"></div>
                  <div className="xp-increase-glow3"></div>
                </div>
                <div className="xp-increase-glow2"></div>
              </div>
            </div>
          </div>
          <div id="account-bar-next-level"></div>
          <h4 className="xp-until-next">{xp} XP</h4>
        </div>
      </div>
      <div className="achievements">
        <h3>Achievements</h3>
        <Achievements
          setXp={setXp}
          player={player}
          alle_turneringer={alle_turneringer}
          cumScores={cumScores.map((score) => score.y)}
        />
      </div>
      <div className="turneringspoeng-chart">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      {alle_turneringer.length ? (
        <div className="margin-top-2">
          <h4>
            {brukernavn} has played {player.tournaments?.length || 0}/
            {alle_turneringer.length} tournaments:
          </h4>
          <table
            id="scoreboard"
            className="scoreboard table striped table-responsive-md"
          >
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Date</th>
                <th style={{ textAlign: "left" }}>Name</th>
                <th style={{ textAlign: "center" }}>#</th>
                <th style={{ textAlign: "left" }}>Points</th>
              </tr>
            </thead>
            {player.tournaments
              ?.sort((a, b) => {
                if (a.date > b.date) return -1;
                if (a.date < b.date) return 1;
                return 0;
              })
              .map((tournament) => {
                return (
                  <tr key={tournament.date}>
                    <td>
                      {moment(tournament.date, "YYYY.MM.DD").format(
                        "Do MMMM YYYY"
                      )}
                    </td>
                    <td style={{ textAlign: "left" }}>
                      <a
                        href={tournament.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {tournament.fullName}
                      </a>
                    </td>
                    <td style={{ textAlign: "center" }}>{tournament.rank}</td>
                    <td style={{ textAlign: "left" }}>{tournament.score}</td>
                  </tr>
                );
              })}
          </table>
        </div>
      ) : null}
    </div>
  );
}

export default Spiller;
